<template>
    <div class="noticeWrap">
       <div class="tipMsg">用于接收订单状态消息</div>
       <div class="processItem">
           第一步： 请先关注公众号：
           <div><img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/mkt_gzh.jpeg" /></div>
       </div>
       <div class="processItem">
           第二步：设置通知用户
           <div class="peoList">
               <el-checkbox-group v-model="checkList">
                 <el-checkbox v-for="item in allTableData" :key="item.userId" :label="item.userId" border>{{item.name}}</el-checkbox>
               </el-checkbox-group>
           </div>
       </div>
       <el-button type="primary" class="myBtn" @click="confirmNoticeUser">确 定</el-button>
    </div>
</template>
<script>
import {queryPageEmployee, fetchUpNotice, fetchGetNoticeList} from "@/api/companyManage.js";
export default {
  name: "enterpriseMember",
  components: {

  },
  data() {
    return {
      allTableData: [],
      checkList: []
    }
  },
  created() {
      this.getAllPeo()
  },
  methods: {
      getAllPeo() {
          let data = {
            pageNum:1, 
            pageSize:1000,
            name:'',
            queryType:'all',
        }
        queryPageEmployee(data).then((res) => {
            if(res.data.pageInfo.list) {
              this.allTableData = res.data.pageInfo.list
              this.getCheckList()
            }
        })
      },
      getCheckList() {
       fetchGetNoticeList().then(res => {
           if(res.code === 200) {
               this.checkList = res.data.map(item => item.userId)
           }
       })
      },
      confirmNoticeUser(){
          let params ={
              userIds: this.checkList
          }
          fetchUpNotice(params).then(res => {
            if(res.code === 200) {
                this.$message({
                  type: "success",
                  message: "操作成功！",
                });
            }
          })
      }
  }
}
</script>
<style scoped>
.peoList{
    padding: 10px;
}
.noticeWrap{
    background: #fff;
    font-size: 14px;
    padding: 10px;
}
.tipMsg{
    color: #f00;
    font-size: 12px;
    padding: 10px;
}
.processItem{
    font-size: 14px;
    padding: 10px;
}
.myBtn{
    display: block;
    margin-left: 20px;
    /* margin: 10px auto; */
}
</style>
